var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          gridHeightAuto: "",
          isTitle: true,
          title: _vm.imprTabInfo.gridItem.title,
          columns: _vm.gridItemColumns,
          data: _vm.gridItemData,
          merge: _vm.imprTabInfo.gridItem.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: !_vm.imprTabInfo.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: false,
                          requestContentsCols:
                            _vm.imprTabInfo.requestContentsCols,
                          tableKey: _vm.imprTabInfo.tableKey,
                          ibmTaskTypeCd: _vm.imprTabInfo.ibmTaskTypeCd,
                          bmTaskUnderTypeCd: _vm.imprTabInfo.ibmTaskUnderTypeCd,
                          acceptBtnShow: _vm.imprTabInfo.acceptBtnShow,
                        },
                        on: { imprChange: _vm.imprChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "customArea",
                              fn: function ({ props }) {
                                return [
                                  _vm._l(
                                    _vm.imprTabInfo.customCols,
                                    function (item) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: item.key,
                                            staticClass: "col-12",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mini-dailog-title",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "pe-7s-angle-right-circle",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.label) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              props.row[item.name]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 border-content-dialog",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.row[item.name]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 border-nocontent-dialog",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s("No Data") +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    }
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "c-table",
        {
          ref: "gridOther",
          attrs: {
            gridHeightAuto: "",
            isTitle: true,
            title: _vm.imprTabInfo.gridOther.title,
            columns: _vm.gridOther.columns,
            data: _vm.gridOther.data,
            usePaging: false,
            filtering: false,
            columnSetting: false,
          },
          on: {
            linkClick: (row, col, idx) =>
              _vm.linkClick(row, row.ibmClassCd, row.sopImprovementId, "other"),
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  !_vm.imprTabInfo.disabled && _vm.imprTabInfo.isOld
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "개선요청",
                          color: "red-6",
                          "text-color": "white",
                        },
                        on: { btnClicked: _vm.addImpr },
                      })
                    : _vm._e(),
                  !_vm.imprTabInfo.disabled && _vm.imprTabInfo.isOld
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "즉시조치",
                          color: "light-blue",
                          "text-color": "white",
                        },
                        on: { btnClicked: _vm.addImmImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }