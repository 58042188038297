<template>
  <div>
    <c-table
      ref="table"
      gridHeightAuto
      :isTitle="true"
      :title="imprTabInfo.gridItem.title"
      :columns="gridItemColumns"
      :data="gridItemData"
      :merge="imprTabInfo.gridItem.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="!imprTabInfo.disabled"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="imprTabInfo.requestContentsCols"
            :tableKey="imprTabInfo.tableKey"
            :ibmTaskTypeCd="imprTabInfo.ibmTaskTypeCd"
            :bmTaskUnderTypeCd="imprTabInfo.ibmTaskUnderTypeCd"
            :acceptBtnShow="imprTabInfo.acceptBtnShow"
            @imprChange="imprChange"
          >
            <template v-slot:customArea="{ props }">
              <template v-for="(item) in imprTabInfo.customCols">
                <div class="col-12" :key="item.key">
                  <div class="row">
                    <div class="col-6">
                      <div class="mini-dailog-title">
                        <i class="pe-7s-angle-right-circle"></i>
                        {{item.label}}
                      </div>
                    </div>
                    <div v-if="props.row[item.name]" class="col-12 border-content-dialog">
                      {{props.row[item.name]}}
                    </div>
                    <div v-else class="col-12 border-nocontent-dialog">
                      {{'No Data'}}
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </component>
        </template>
      </template>
    </c-table>
    <c-table
      ref="gridOther"
      gridHeightAuto
      :isTitle="true"
      :title="imprTabInfo.gridOther.title"
      :columns="gridOther.columns"
      :data="gridOther.data"
      :usePaging="false"
      :filtering="false"
      :columnSetting="false"
      @linkClick="(row, col, idx) => linkClick(row, row.ibmClassCd, row.sopImprovementId, 'other')"
    >
      <template slot="table-button">
        <q-btn-group outline >
            <!-- 개선요청 -->
          <c-btn 
            v-if="!imprTabInfo.disabled&&imprTabInfo.isOld" 
            :showLoading="false"
            label="개선요청" 
            color="red-6"
            text-color="white"
            @btnClicked="addImpr" />
            <!-- 즉시조치 -->
          <c-btn 
            v-if="!imprTabInfo.disabled&&imprTabInfo.isOld" 
            :showLoading="false"
            label="즉시조치" 
            color="light-blue"
            text-color="white"
            @btnClicked="addImmImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'imprTab',
  props: {
    imprTabInfo: {
      type: Object,
      default: () => ({
        key: '', // 
        requestContentsCols: [], // 개선요청 전달 목록
        ibmTaskTypeCd: '',
        ibmTaskUnderTypeCd: '',
        customCols: [], // custom형식의 데이터 목록
        requestContents: '',
        disabled: false,
        isOld: false,
        gridItem: {
          listUrl: '',
          param: null, 
          title: '항목 별 개선사항 목록', // 항목 별 개선사항 목록
          data: [],
          merge: [],
          columns: [],
          research: {
            impr: '',
            item: '',
          },
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridItem: {
        data: [],
      },
      gridOther: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: '사업장',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          // },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            // 구분
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: '제목',
            align: 'left',
            sortable: false,
            type: 'link',
          },
          // {
          //   name: 'actionContents',
          //   field: 'actionContents',
          //   // 조치내용
          //   label: '조치내용',
          //   align: 'left',
          //   style: 'width:400px',
          //   sortable: false,
          // },
          // {
          //   name: 'actionCompleteDate',
          //   field: 'actionCompleteDate',
          //   // 조치완료일
          //   label: '조치완료일',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          // {
          //   name: 'customCol',
          //   field: 'customCol',
          //   label: '',
          //   align: 'center',
          //   style: 'width:30px',
          //   type: 'custom',
          //   sortable: false
          // }
        ],
        data: [],
      },
      requestImprRow: null,
      editable: true,
      impListUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'imprTabInfo.gridItem.research.impr'() {
      this.getList();
    }
  },
  computed: {
    gridItemColumns() {
      // 개선/진행상태
      return this.$_.concat(this.imprTabInfo.gridItem.columns, [{
        name: 'customCol',
        field: 'customCol',
        label: '',
        align: 'center',
        style: 'width:30px',
        type: 'custom',
        sortable: false
      }])
    },
    gridItemData() {
      return this.imprTabInfo.gridItem.data && this.imprTabInfo.gridItem.data.length > 0 
        ? this.imprTabInfo.gridItem.data : this.gridItem.data
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      // code setting
      // list setting
      this.getList();
      this.getImprList();
    },
    getList() {
      if (this.imprTabInfo.gridItem.listUrl) {
        this.$http.url = this.imprTabInfo.gridItem.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.imprTabInfo.gridItem.param
        this.$http.request((_result) => {
          this.gridItem.data = _result.data;
        },);
      }
    },
    getImprList() {
      if (this.imprTabInfo.key) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd, 
          ibmTaskUnderTypeCd: this.imprTabInfo.ibmTaskUnderTypeCd, 
          relationTableKey: this.imprTabInfo.key
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridOther.data = _result.data;
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, ibmClassCd, sopImprovementId, flag) {
      if (ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선요청 상세';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치 상세';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        sopImprovementId: sopImprovementId,
      };
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      if (flag === 'item') {
        this.requestImprRow = row
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.closeCallback = this.closeImprOtherPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (this.imprTabInfo.gridItem.research) {
          this.$set(this.imprTabInfo.gridItem.research, 'item', uid())
        }
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getList();
      }
    },
    closeImprOtherPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprList();
    },
    /* eslint-disable no-unused-vars */
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
        ibmTaskUnderTypeCd: this.imprTabInfo.ibmTaskUnderTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addImmImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
        ibmTaskUnderTypeCd: this.imprTabInfo.ibmTaskUnderTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprList();
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
